import { defineStore } from 'pinia'

/*
 * Store management for the transaction
 */
export const useTransactionStore = defineStore('transactionstore', {
  state: () => ({
    // Transaction
    transactionHash: '',
    transactionIndex: 0,
    fromAddress: '',
    recipientName: '',
    toAddress: '',
    contract: '',
    token: '',
    value: '0',
    data: [],
    // Transaction Receipt
    transactionReceipt: {},
    blockExplorerUrl: ''
  }),
  getters: {
    getFromAddress(state) {
      return state.fromAddress
    },
    getToAddress(state) {
      return state.toAddress
    },
    getValue(state) {
      return state.value
    }
  },
  actions: {
    fetchRecipientInfoFromWp: async function(restUrl) {
      let name = ''
      let address = '0x2dE46dA129FF70d63012a0BF17a8088cEcB47C5B' // Greenhood wallet of Sebastian
      await fetch(
        restUrl+'?rest_route=/greenhood/get-recipient-info',
        {
          method: 'GET',
          mode: 'cors'
        }
      ).then((res) =>
        res.json()
      ).then((data) => {
        name = data['recipient_name']
        address = data['recipient_address']
      }).catch((error) => {
        throw (error)
      });
      this.recipientName = name
      this.toAddress = address
    }
  }
})