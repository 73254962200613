<script setup>
import ConnectMetamask from "./components/ConnectMetamask.vue";
import WalletConnect from "./components/WalletConnect.vue";
import Order from "./components/Order.vue";
import Debug from "./components/Debug.vue";
import { useStatusStore } from "./stores/useStatusStore.js";
import { paymentStatusses, useOrderStore } from "./stores/useOrderStore.js";
import { useTransactionStore } from "./stores/useTransactionStore.js";
import { ref } from "vue";

const ENV = import.meta.env.VITE_ENVIRONMENT
const statusStore = useStatusStore()
const orderStore = useOrderStore()
const transactionStore = useTransactionStore()

const urlSearchParams = new URL(window.location).searchParams;
let tokenColor = ref()
tokenColor.value = statusStore.tokenColor
let restURL = ''

// get payment / checkout id
if(urlSearchParams.has('pid')) {
  orderStore.paymentId = urlSearchParams.get('pid')
}
// get restURL
if(urlSearchParams.has('resturl')) {
  restURL = urlSearchParams.get('resturl')
}

statusStore.updateWallet('', [])
statusStore.updateStatus(1, 'Ready, click on connect')

// Fallback to test environment
if (restURL === '') {
  restURL = 'https://kash.a.sebastix.dev/'
}
if (import.meta.env.VITE_ENVIRONMENT === 'development' && restURL !== 'http://localhost/wp/') {
  restURL = 'http://localhost/wp/'
}
let tokenLogoUrl = new URL('./assets/eure-logo.svg', import.meta.url), tokenLogo = ref(tokenLogoUrl.href);
orderStore.restUrl = restURL
transactionStore.fetchRecipientInfoFromWp(orderStore.restUrl)
if (orderStore.paymentId === '12345' && restURL !== 'https://kash.a.sebastix.dev/') {
  orderStore.fetchOrderInfoFromWP()
}

function connect() {
  if (statusStore.code === 1) {
    statusStore.updateStatus(2, 'Choose wallet')
  } else {
    statusStore.updateStatus(1, 'Ready, click on connect')
  }
}

/**
 * Emit method which is called from the Order component.
 * Call to WP API endpoint.
 *
 * @param paymentStatus
 * @param paymentId
 */
async function updateOrderInWebshop(paymentStatus, paymentId) {
  // Send POST request with Fetch API to the webshop
  try {
    if (paymentId === undefined) throw ('No paymentId is set to update an order.')
    const data = {
      'payment_status': paymentStatus,
      'pid': paymentId,
      'transactionHash': transactionStore.transactionHash,
      'transactionIndex': transactionStore.transactionIndex,
      'paymentToken': transactionStore.token
    }
    await fetch(
        orderStore.restUrl+'?rest_route=/greenhood/payment/order',
        {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(data)
        }
    ).then((res) =>
        res.json()
    ).then((data) => {
      orderStore.paymentStatus = data.order_status
      orderStore.orderId = data.order_id
      if (data.message !== '') {
        orderStore.addData({ 'message': data.message })
      }
    }).catch((error) => {
      throw (error)
    });
  } catch (error) {
    console.log('A Fetch API error occurred.')
    console.log(JSON.stringify(error))
  }
}

/**
 *
 * @returns {Promise<any>}
 */
async function fetchTokens() {
  return await fetch(
      'https://greenhood.nl/betaalonline/regiomunt.json',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'GET',
        mode: 'cors'
      }
  ).then((res) => {
    if (res.ok === false) {
      throw new Error()
    }
    return res.json()
  }).then((data) => {
    return data.coins
  }).catch((error) => {
    throw (error)
  });
}

/**
 *
 * @param tokenUrl
 * @returns {Promise<any>}
 */
async function fetchTokenInfo(tokenUrl = '') {
  if (tokenUrl === '') {
    await fetchTokens().then((tokens) => {
      // @todo select token url based on active paymentToken
      const res = tokens.find((item) => item.symbol === token)
      tokenUrl = res.uri
    })
  }
  return await fetch(
    tokenUrl,
    {
      method: 'GET',
      mode: 'cors'
    }
  ).then((res) => {
    return res.json()
  }).then((data) => {
    console.log(data)
    return data
  }).catch((error) => {
    throw (error)
  });
}

/**
 * Emit method which is called from the Order component.
 * Set color and logo of paymentToken.
 */
async function setTokenSettings(token) {
  await fetchTokens().then((tokens) => {
    // Get token from tokens array
    const res = tokens.find((item) => item.symbol === token)
    if (res !== undefined) {
      tokenColor.value = '#'+res.color
      statusStore.tokenColor = '#'+res.color
      tokenLogoUrl = new URL(res.logo)
      tokenLogo.value = tokenLogoUrl.href
    }
  })
}

/**
 * Set local logo file.
 */
function setLocalLogo (token) {
  if (token.toLowerCase() === 'xdai') {
    tokenLogoUrl = new URL('./assets/xdai-logo.png', import.meta.url)
  }
  else if (token.toLowerCase() === 'kash') {
    tokenLogoUrl = new URL('./assets/kash-logo.svg', import.meta.url)
  }
  else if (token.toLowerCase() === 'bant') {
    tokenLogoUrl = new URL('./assets/bant-logo.png', import.meta.url)
  }
  else if (token.toLowerCase() === 'bup') {
    tokenLogoUrl = new URL('./assets/bup-logo.png', import.meta.url)
  }
  tokenLogo.value = tokenLogoUrl.href
}

/**
 * Toggle debug element with ctrl + d key
 * @type {*[]}
 */
let keys = []
document.addEventListener('keydown', (event) => {
  keys.push(event.key)
  if (keys.includes('Control') && keys.includes('d')) {
    statusStore.debug = (statusStore.debug === false)
  }
})
document.addEventListener('keyup', (event) => {
  if (event.key !== 'd' || !event.ctrlKey) {
    keys = []
  }
})

</script>

<template>
  <header class="token-color">
    <div class="fixed">
      <img :src="tokenLogo" class="mt-2 ml-2 h-[80px]" :alt="orderStore.paymentToken" :title="orderStore.paymentToken" v-if="orderStore.paymentToken">
    </div>
    <div class="fixed mt-6 left-[100px] p-4 w-76 rounded-full border-2 token-color bg-gray-100 text-center"  v-if="statusStore.code === 12 || statusStore.code === 23">
       {{ statusStore.getRoundedBalanceActiveAccount }} {{ orderStore.paymentToken }}
    </div>
    <div
        v-if="orderStore.paymentStatus === paymentStatusses.Completed && orderStore.paymentId === 12345 && ENV !== 'production'"
        class="z-50 fixed w-screen h-screen bg-neutral-950 bg-opacity-50">
      <div class="fixed inset-x-1/4 inset-y-1/3 text-center w-1/2 h-fit p-8 font-bold bg-green-100 z-50 text-3xl shadow-2xl">
        It looks like you've opened this dApp without any order from the webshop.
        <br />
        <br />
        Go to the <a :href="[ ENV !== 'production' ? 'http://localhost/wp' : 'wp']" class="underline" target="_blank">webshop</a> to place a order or <a href="#" class="underline" @click="orderStore.paymentStatus=paymentStatusses.Pending">click here</a> to continue
      </div>
    </div>
    <div class="fixed p-4 mt-6 right-72 text-green-700 font-bold" v-if="Object.keys(statusStore.chain).length !== 0">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 428 428" class="mb-1 mr-2 fill-green-700 h-[24px] inline">
        <path d="M125.8,243.7c12.3,0,24.3-4.1,34-11.6l-78-78c-18.8,24.3-14.3,59.3,10,78.1
          C101.6,239.6,113.5,243.7,125.8,243.7L125.8,243.7z"/>
                <path d="M357.8,188c0-12.3-4.1-24.3-11.6-34l-78,78c24.3,18.8,59.2,14.3,78-10
          C353.7,212.3,357.8,200.3,357.8,188z"/>
                <path d="M397.1,103.1l-34.5,34.5c27.8,33.3,23.4,82.9-9.9,110.7c-29.2,24.4-71.6,24.4-100.8,0L214,286.2
          l-37.8-37.8c-33.3,27.8-82.9,23.4-110.7-9.9c-24.4-29.2-24.4-71.6,0-100.8L47.8,120L31,103.1C10.7,136.5,0,174.9,0,214
          c0,118.2,95.8,214,214,214s214-95.8,214-214C428.1,174.9,417.3,136.5,397.1,103.1z"/>
                <path d="M368.8,66.3c-81.5-85.5-216.9-88.7-302.4-7.2c-2.5,2.4-4.9,4.8-7.2,7.2c-5.3,5.6-10.3,11.4-15,17.5
          L214,253.7L383.8,83.8C379.2,77.7,374.1,71.9,368.8,66.3z M214,28c50,0,96.6,19.3,131.6,54.5L214,214.1L82.4,82.5
          C117.4,47.3,164,28,214,28z"/>
      </svg>
      {{ statusStore.chain.name }} Chain
    </div>
    <div class="fixed right-0 mt-6 mr-6">
      <button class="p-4 w-64" @click="connect" v-if="statusStore.wallet.name === ''">Connect</button>
      <div class="p-4 w-64 rounded-full border-2 token-color bg-gray-100 text-center" v-if="statusStore.getAccounts">
        {{ statusStore.getAccounts[0].address.slice(0, 5) + '...' + statusStore.getAccounts[0].address.slice(-4) }}
      </div>
      <div v-if="statusStore.code === 2">
        <ConnectMetamask v-if="statusStore.wallet.name !== 'walletconnect'" />
        <WalletConnect v-if="statusStore.wallet.name !== 'metamask'" />
      </div>
    </div>
  </header>
  <main class="container token-color">
    <div class="grid grid-cols-6 content-center min-h-screen">
      <Order @updateOrderInWP="updateOrderInWebshop" @setTokenSettings="setTokenSettings" />
    </div>
  </main>
  <footer>
    <Debug v-if="statusStore.debug === true" />
  </footer>
</template>

<style scoped lang="scss">
  h1 {
    &:hover {
      color: orangered;
    }
  }
  :global(.token-color) {
    border-color: v-bind(tokenColor);
  }
  :global(button) {
    border-color: v-bind(tokenColor);
  }
</style>
