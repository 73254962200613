<script setup>
  import { version as vue_version } from 'vue'
  import { useStatusStore } from "../stores/useStatusStore.js";
  import { useOrderStore } from "../stores/useOrderStore.js";
  import { useTransactionStore } from "../stores/useTransactionStore.js";

  const APP_VERSION = __APP_VERSION__
  const VUE_VERSION = vue_version
  const APP_NAME = import.meta.env.VITE_APP_NAME
  const MODE = import.meta.env.MODE
  const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT

  const statusStore = useStatusStore()
  const orderStore = useOrderStore()
  const transactionStore = useTransactionStore()

</script>
<template>
  <div class="fixed left-0 bottom-0 w-full p-2 bg-neutral-950 text-xs text-green-500 grid grid-cols-5 font-mono h-[280px]">
    <div class="col-span-full text-center">
      Toggle this debug element with CTRL + D keys or <a href="#" class="underline" @click="statusStore.debug=false">click here</a> to close
    </div>
    <div>
      <span class="underline">APP</span>
      <ul>
        <li>Version: {{ APP_VERSION }}</li>
        <li>VUE: version {{ VUE_VERSION }}</li>
        <li>APP_NAME: {{ APP_NAME }}</li>
        <li>MODE: {{ MODE }}</li>
        <li>ENV: {{ ENVIRONMENT }}</li>
      </ul>
    </div>
    <div>
      <span class="underline">STATUS</span>
      <ul>
        <li>Device: {{ statusStore.device }}</li>
        <li>Code: {{ statusStore.getCode }}</li>
        <li>Text: {{ statusStore.getText }}</li>
        <li>tokenColor: {{ statusStore.tokenColor }}</li>
        <li>Connected wallet: {{ statusStore.wallet.name }}</li>
        <li>Connected chain: {{ statusStore.chain.name }}</li>
        <li>activeAccountIndex: {{ statusStore.activeAccountIndex }}</li>
        <li>Accounts:
          <ul class="list-disc pl-4">
            <li v-for="account in statusStore.wallet.accounts" :key="account">
              {{ account.address }}
              <br />balance: {{ account.balance }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <span class="underline">ORDER INFO FROM WEBSHOP</span>
      <ul>
        <li>payment_id: {{ orderStore.getPaymentId }}</li>
        <li>payment_status: {{ orderStore.getPaymentStatus }}</li>
        <li>payment_amount: {{ orderStore.getPaymentAmount }} {{ orderStore.getPaymentSymbol }}</li>
        <li>payment_token: {{ orderStore.paymentToken }}</li>
        <li>order_id: {{ orderStore.getOrderId }}</li>
        <li>post_password: {{ orderStore.postPwd }}</li>
        <li>restUrl: {{ orderStore.restUrl }}</li>
        <li>data / log:
          <ul class="list-disc pl-4">
            <li v-for="item in orderStore.getData" :key="item">
              {{ item }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <span class="underline">TRANSACTION</span>
      <ul>
        <li>transactionHash: {{ transactionStore.transactionHash }} </li>
        <li>transactionIndex: {{ transactionStore.transactionIndex }} </li>
        <li>from: {{ transactionStore.fromAddress }} </li>
        <li>to: {{ transactionStore.toAddress }}</li>
        <li>name: {{ transactionStore.recipientName }}</li>
        <li>contract: {{ transactionStore.contract }}</li>
        <li>token: {{ transactionStore.token }}</li>
        <li>value: {{ transactionStore.value }}</li>
      </ul>
    </div>
    <div>
      <span class="underline">TRANSACTION RECEIPT</span>
      <ul>
        <li>blockHash: {{ transactionStore.transactionReceipt.blockHash }}</li>
        <li>blockNumber: {{ transactionStore.transactionReceipt.blockNumber }}</li>
        <li>status: {{ transactionStore.transactionReceipt.status }}</li>
        <li v-if="transactionStore.blockExplorerUrl !== ''">
          <a :href="transactionStore.blockExplorerUrl" target="_blank">view in explorer</a>
        </li>
      </ul>
    </div>
  </div>
</template>