import { defineStore } from 'pinia'

/**
 * Enum
 *
 * @type {{PendingConfirmationByUser: string, Test: string, PendingConfirmationByChain: string, Cancelled: string, PendingConfirmation: string, Pending: string, Success: string, Open: string}}
 */
export const paymentStatusses = {
  Open: 'open',
  PendingPayment: 'pending',
  Processing: 'processing',
  PendingConfirmation: 'pending_confirmation',
  PendingConfirmationByUser: 'pending_confirmation_by_user',
  PendingConfirmationByChain: 'pending_confirmation_by_chain',
  Success: 'success',
  Completed: 'completed',
  Cancelled: 'cancelled',
  Test: 'TEST'
}
/*
 * Store management for the order
 */
export const useOrderStore = defineStore('orderstore', {
  state: () => ({
    paymentId: 12345,
    paymentStatus: paymentStatusses.Open,
    paymentAmount: '0.01',
    paymentSymbol: 'xDAI',
    paymentToken: '',
    postPwd: '',
    orderId: 0,
    data: [],
    restUrl: '',
  }),
  getters: {
    getPaymentId(state) {
      return state.paymentId
    },
    getPaymentStatus(state) {
      return state.paymentStatus
    },
    getPaymentAmount(state) {
      return state.paymentAmount
    },
    getPaymentSymbol(state) {
      return state.paymentSymbol
    },
    getOrderId(state){
      return state.orderId
    },
    getData(state) {
      return state.data
    }
  },
  actions: {
    addData: function (data = {}) {
      this.data.push(data)
    },
    fetchOrderInfoFromWP: async function () {
      let data = {
        'pid': this.paymentId
      }
      await fetch(
        this.restUrl+'?rest_route=/greenhood/get-order',
        {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(data)
        }
      ).then((res) =>
        res.json()
      ).then((data) => {
        this.orderId = data.order_id
        this.paymentAmount = data.amount
        this.paymentToken = data.payment_token
        this.paymentStatus = data.payment_status
        this.postPwd = data.post_password
      }).catch((error) => {
        throw (error)
      });
    }
  }
})